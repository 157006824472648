import { captureErrorAsync } from "./capture-error-async";
interface GlobalWaiters {
  [k: string]: Promise<NonNullable<Window[keyof Window]>>;
}

const GLOBAL_WAITERS: GlobalWaiters = {};

export function domReadyLegacy(callbackFunc: () => void): void {
  if (document.readyState !== "loading") {
    // Document is already ready, call the callback directly
    callbackFunc();
  } else if (document.addEventListener) {
    // All modern browsers to register DOMContentLoaded
    document.addEventListener("DOMContentLoaded", callbackFunc);
  } else {
    // Old IE browsers
    // @ts-ignore
    document.attachEvent("onreadystatechange", function () {
      if (document.readyState === "complete") {
        callbackFunc();
      }
    });
  }
}

export function domReady(): Promise<unknown> {
  return new Promise((resolve, _reject) => {
    if (document.readyState !== "loading") {
      // Document is already ready, call the callback directly
      resolve(undefined);
    } else if (document.addEventListener) {
      // All modern browsers to register DOMContentLoaded
      document.addEventListener("DOMContentLoaded", resolve);
    } else {
      // Old IE browsers
      // @ts-ignore
      document.attachEvent("onreadystatechange", function () {
        if (document.readyState === "complete") {
          resolve(undefined);
        }
      });
    }
  });
}

export function globalVarReady<T extends keyof Window>(
  name: T,
  opts = { reportErrors: true }
): Promise<NonNullable<Window[T]>> {
  if (GLOBAL_WAITERS[name] && process.env.NODE_ENV !== "test") {
    // @ts-ignore
    return GLOBAL_WAITERS[name];
  }

  let attempts = 0;
  const timeout = process.env.NODE_ENV === "test" ? 1 : 100;

  const promise = new Promise<NonNullable<Window[T]>>(function check(
    resolve,
    reject
  ) {
    attempts += 1;

    if (attempts > 100) {
      const err = new Error(`Timed out waiting on window.${name} to appear`);
      if (opts.reportErrors) {
        captureErrorAsync(err);
      }
      return reject(err);
    }

    // if there is a testing function, check it
    if (window[name]) {
      // @ts-ignore
      return resolve(window[name]);
    }

    return setTimeout(() => check(resolve, reject), timeout);
  });

  GLOBAL_WAITERS[name] = promise;

  return promise;
}
export function analyticsReady(): Promise<typeof window.analytics> {
  return globalVarReady("analytics");
}

export async function analyticsFullyLoaded(): Promise<typeof window.analytics> {
  await globalVarReady("analytics");

  return new Promise((resolve) => {
    if (typeof window.analytics.ready === "function") {
      window.analytics.ready(() => {
        setTimeout(resolve); // allow for other things to run first
      });
    } else {
      setTimeout(resolve);
    }
  });
}
